import { MainLayoutComponent } from './main-layout/main-layout.component';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { AddBankComponent } from './add-bank/add-bank.component';
import { SetUserComponent } from './set-user/set-user.component';
import { SetVendorComponent } from './set-vendor/set-vendor.component';
import { CheckReportComponent } from './check-report/check-report.component';
import { AuthGuard } from './shared/auth.guard';
import { CheckWriterComponent } from './check-writer.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [ 
  { path: '', redirectTo:'login', pathMatch: 'full'},
  { path: 'login', component: LoginLayoutComponent, children : [
    {path: '', component: LoginComponent}
  ]},
  {path: 'main', component: MainLayoutComponent, children: [
    { path: '', redirectTo: 'check', pathMatch: 'full'},
    {path:'check', component: CheckWriterComponent, canActivate: [AuthGuard],  data: {
      role: 7
    }},
    {path:'report', component: CheckReportComponent, canActivate: [AuthGuard],  data: {
      role: 7  }},
    {path:'vendor', component: SetVendorComponent, canActivate: [AuthGuard], data: {
      role: 3
    }},
    {path:'user', component: SetUserComponent, canActivate: [AuthGuard], data: {
      role: 1
    }},
    {path:'bank', component: AddBankComponent, canActivate: [AuthGuard],  data: {
      role: 1
    }}
  ]},
  
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="container-xxl m-3 p-1">
    <form #form method="post" id="form" (submit)="onFormSubmit($event)">
    <div class="row">
       <div class="col-4">
          <div class="container dx-card">
            
             <dx-form
             [(formData)]="user"
             labelLocation="left"
             [showValidationSummary]="true"
             validationGroup = "vendorData"
             >
             <dxi-item dataField="username" editorType="dxAutocomplete"
             [editorOptions]="{ dataSource: allusers,showClearButton:false,valueExpr:'username',searchTimeout:200}"
                        
             >
                <dxi-validation-rule
                   type="required"
                   message="Username name is required">
                </dxi-validation-rule>
                 <dxi-validation-rule type="async" message="User already exists" [validationCallback]="usernameValidation"></dxi-validation-rule> 
             </dxi-item>
             <dxi-item dataField="password"></dxi-item>
             <dxi-item dataField="role" editorType="dxRadioGroup"  [editorOptions] = "{items:userRoles, displayExpr:'name', valueExpr:'value',layout:'horizontal', value:user.role}"></dxi-item>
             <dxi-item dataField="display_name">
                <dxi-validation-rule
                   type="required"
                   message="Display name is required">
                </dxi-validation-rule>
             </dxi-item>
           
             <dxi-item [template]="'actionTemplate'">
                <dxo-label [visible]="false"></dxo-label>
             </dxi-item>
             <div *dxTemplate="let content of 'actionTemplate'">
                <div class="group-container">
                   <dx-button class="right-align-buttons" type="normal" text="Cancel" >
                   </dx-button>
                   <dx-button class="right-align-buttons" type="success" text="Update"
                   [useSubmitBehavior]="true" validationGroup="vendorData" >
                   </dx-button>
                </div>
             </div>
             </dx-form>
             
          </div>
       </div>
       <div class="col-8">
          <div class="container dx-card">
             <div class="row">
                <div class="col">
                  <h3>Unassigned Accounts</h3>
                     <dx-list 
                     #list
                     [dataSource]="availableBanks"
                     [height]="400"
                     [selectionMode]="'all'"
                     [selectAllMode]="'allPages'"
                     [showSelectionControls]="true"
                     [(selectedItems)]="selectedBanks"
                     [searchEnabled]="true"
                     searchExpr="dba"
                     keyExpr = "bankID"
                     searchMode="contains">
                     <div *dxTemplate="let data of 'item'">
                        <div class="row">
                           <div class="col">{{data.dba}}</div>
                           <div class="col text-right"><small>{{data.accountName}}</small></div>
                        </div>
                        <div class="row">
                           <div class="col text-right"><small>{{data.bankName}}</small></div>
                        </div>
                     </div>
                     </dx-list>
                </div>
                <div class="col-1 align-self-center pick-list-control">
                  <dx-button
                     icon="chevronright"                     
                     type="success"
                     stylingMode="text"
                     (onClick)="assignSelectedBanks($event)"
                    >
                  </dx-button>
                  <dx-button
                     icon="chevrondoubleright"
                     type="success"
                     stylingMode="text"
                     (onClick)="assignAllBanks($event)"
                    >
                  </dx-button>
                  <dx-button
                     icon="chevronleft"                    
                     type="success"
                     stylingMode="text"
                     (onClick)="unAssignSelectedBanks($event)"
                    >
                  </dx-button>
                  <dx-button
                     icon="chevrondoubleleft"
                     type="success"
                     stylingMode="text"
                     (onClick)="unAssignAllBanks($event)"
                    >
                  </dx-button>
                </div>
                <div class="col">
                  <h3>Assigned Accounts</h3>
                  <dx-list 
                  #list
                  [dataSource]="assignedBanks"
                  [height]="400"
                  [selectionMode]="'all'"
                  [selectAllMode]="'allPages'"
                  [showSelectionControls]="true"
                  [(selectedItems)]="selectedAssignedBanks"
                  [searchEnabled]="true"
                  searchExpr="dba"
                  keyExpr = "bankID"
                  searchMode="contains">
                  <div *dxTemplate="let data of 'item'">
                    <div class="row">
                       <div class="col">{{data.dba}}</div>
                       <div class="col text-right"><small>{{data.accountName}}</small></div>
                    </div>
                    <div class="row">
                       <div class="col text-right"><small>{{data.bankName}}</small></div>
                    </div>
                  </div>
                  </dx-list>
                </div>
             </div>
          </div>
         </div>
    </div>
 </form>
 </div>
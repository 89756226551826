import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public authService: AuthService,
    public router: Router) {}
    
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.authService.isLoggedIn !== true) {
      this.router.navigateByUrl('login');
    }  
    const userRole = this.authService.user.role;
      console.log(userRole);
      console.log(route.data.role);
      // if current user role number is higher than data.role number then the user is not authorized.
      if (route.data.role && userRole > route.data.role) {
        this.router.navigate(['/login']);
        return false;
      }
      return true;
  }
  
}

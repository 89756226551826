import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css']
})
export class RootComponent implements OnInit {
  
  constructor(private authService: AuthService) { }

  ngOnInit(): void {

  }
  logOut() {
    this.authService.doLogout();
  }

}

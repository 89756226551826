<!-- Check Payment Form-->
<div class="container-xxl m-3 p-1">
   <div class="row">
      <div class="col-4">
         <div class="container dx-card py-2">
            <h3>Make a Payment</h3>
            <form #form method="post" enctype="multipart/form-data" id="form" (submit)="onFormSubmit($event)">
            <dx-form
            [(formData)]="check">
            <dxi-item dataField="bank">
               <dx-select-box
               [dataSource]="banks"
               displayExpr="dba"
               valueExpr="this"
               [(value)]="check.bank"
               [searchEnabled]="true"
               [searchExpr]= '["bankName", "dba","accountName"]'
               [searchTimeout]="10"
               (onValueChanged) = "bankSelected($event)"
               placeholder="Select Bank..."
               >
               <div *dxTemplate="let data of 'item'" class="custom-item">
                  <div class="row">
                     <div class="col">{{data.dba}}</div>
                     <div class="col text-right"><small>{{data.accountName}}  {{data.account}}</small></div>
                  </div>
                  <div class="row">
                     <div class="col text-right"><small>{{data.bankName}}</small></div>
                  </div>
               </div>
               </dx-select-box>
               <dxi-validation-rule
                  type="required"
                  message="Bank is required">
               </dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="payment_method" [template]="'select_payment'">
            </dxi-item>
            <div *dxTemplate="let data of 'select_payment'"> 
               <dx-button-group #paymentOption
               [items]="mops"
               keyExpr="text"
               stylingMode="outlined"
               [selectedItemKeys]="['Check']"
               (onItemClick) = "methodOfPaymentClick($event)"
               >
               </dx-button-group> 
            </div>
            
            <dxi-item dataField="Check_Number" >
               <dx-text-box [value]="nextCheck" [readOnly]="true"></dx-text-box>
            </dxi-item>
            
            <dxi-item dataField="vendor">
               <dx-select-box
               [dataSource]="vendors"
               displayExpr="vendor_name"
               valueExpr="this"
               [(value)]="check.vendor"
               [searchEnabled]="true"
               [searchTimeout]="10"
               placeholder="Select Payee Name..."
               (onValueChanged) = "vendorSelected($event)"
               >
               <div *dxTemplate="let data of 'item'" class="custom-item">
                  <div class="row">
                     <div class="col">{{data.vendor_name}}</div>
                  </div>
                  <div class="row">
                     <div class="col text-right">
                        <span *ngIf=data.isMerchandise>Vendor&nbsp;</span> 
                        <span *ngIf=data.isFuel>Fuel Vendor&nbsp;</span>
                        <span *ngIf=data.isEmployee>Employee&nbsp;</span>
                        <span *ngIf=data.isExpenses>Expense&nbsp;</span>
                     </div>
                  </div>
               </div>
               </dx-select-box>
               <dxi-validation-rule
                  type="required"
                  message="Payee is required">
               </dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="amount">
               <dx-number-box 
               format="$ #,##0.##" 
               [(value)]="check.amount"
               [min]="0.01"
               [showClearButton]="true"
               (onValueChanged)="amountChanged($event)">
               
               </dx-number-box>
               <dxi-validation-rule
                  type="required"
                  message="Amount is required">
               </dxi-validation-rule>
               <dxi-validation-rule
                  type="range"
                  [max] ="maxAmount"
                  message="Amount should be less than {{maxAmount}}">
               </dxi-validation-rule>
            </dxi-item>
            <!-- <dxi-item dataField="in_words" [editorOptions] = "{readOnly:true}"></dxi-item> -->
            <dxi-item dataField="memo">
               <dx-text-box
               [(value)]="check.memo"
               [maxLength] = "142"
               [showClearButton]="true"
               >
               </dx-text-box>
               <dxi-validation-rule
                  type="required"
                  message="Memo is required">
               </dxi-validation-rule>
               <dxi-validation-rule type="stringLength" [max]="143" message="Max 140 characters allowed"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="File" [template]="'fileTemplate'">
            </dxi-item>
            <div *dxTemplate="let data of 'fileTemplate'">  
               <dx-file-uploader
               selectButtonText="Browse"
               #fileUploader
               uploadUrl="https://check.quicktrackinc.com/api/upload"
               accept="image/*,.pdf"
               [allowedFileExtensions]="['.jpg','.png','.pdf','.tiff','.jpeg']"
               uploadMode="instantly"
               [multiple]="true"
               (onBeforeSend) = "beforeUpload($event)"
               name="filename"
               (onUploaded) = "fileUploaded($event)"
               labelText="or drop the invoice here.">
               </dx-file-uploader> 
            </div>
            <dxi-item itemType="group" [colCount]="2">
               <dxi-item 
               itemType="button"
               horizontalAlignment="left"
               [buttonOptions]="resetButtonOptions">
            </dxi-item>
               <dxi-item 
               itemType="button"
               horizontalAlignment="left"
               [buttonOptions]="buttonOptions">
            </dxi-item>
            
            </dxi-item>
            
       <!--
         <dxi-item [template]="'actionTemplate'">
            <dxo-label [visible]="false"></dxo-label>
         </dxi-item>
 
         <div *dxTemplate="let content of 'actionTemplate'">
            <div class="group-container">
            <dx-button class="right-align-buttons" type="normal" text="Reset"
                        (onClick)="onResetForm($event)">
            </dx-button>
            <dx-button class="right-align-buttons" type="success" text="Submit"
                        [useSubmitBehavior]="true">
            </dx-button>
            </div>
         </div>
      -->
            </dx-form>
            </form>
         </div>
      </div>
      <div class="col-8">
         <div class="container dx-card py-2">
            <h3>Recent Checks</h3>
            <dx-data-grid #dataGrid id="dataGrid"
            [dataSource]="recentChecks"
            [rowAlternationEnabled]="true"
            keyExpr="id"
            [allowColumnReordering]="false"
            [allowColumnResizing]="true"
            (onRowPrepared) = "dataGridPrepared($event)"
            >
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager 
                [showPageSizeSelector]="true"
                [allowedPageSizes]="[10]"
                [showInfo]="true">
            </dxo-pager>
            
            <dxi-column dataField="created_date" width="100" caption="Date" width="75" [allowSorting]="false"></dxi-column>
            <dxi-column dataField ="checkno" caption="Check" width="55" [allowSorting]="false"></dxi-column>
            <dxi-column dataField ="dba" width="120" [allowSorting]="false"></dxi-column>
            <dxi-column dataField="payee" width="200" [allowSorting]="false"></dxi-column>
            <dxi-column dataField="amount" [format]="{ style: 'currency', currency: 'USD'}" [allowSorting]="false" width="90"></dxi-column>
            <dxi-column dataField="memo" [allowSorting]="false"></dxi-column>
            <dxi-column dataField="status" cellTemplate="cellTemplate" [allowSorting]="false"></dxi-column>
            <div *dxTemplate="let data of 'cellTemplate'">
               <dx-drop-down-button
               text="Options"
               [items]="actions"
               keyExpr="id"
               displayExpr="text"
               width="80%"
               (onItemClick)="showVoidBox(data, $event)">
               </dx-drop-down-button>
            </div>
            </dx-data-grid>
         </div>
      </div>
   </div>
</div>
<dx-popup
title="Void Check"
[width]="300"
height="auto"
[dragEnabled]="false"
[resizeEnabled]="false"
[(visible)]="voidBoxVisible">
<div *dxTemplate="let data of 'content'">
   <dx-form [formData]="selectedCheck">
      <dxi-item [template]="'isNotVoided'" [visible]="!!selectedCheck.canVoid"></dxi-item>
      <div *dxTemplate="let content of 'isNotVoided'">
         <h4>Please confirm to void!</h4>
      </div>
      <dxi-item [template]="'isVoided'" [visible]="!!!selectedCheck.canVoid"></dxi-item>
      <div *dxTemplate="let content of 'isVoided'">
         <h4>This is a voided check!</h4>
      </div>
      <dxi-item [template]="'checkInfoTemplate'"  >
         <dxo-label [visible]="false"></dxo-label>
      </dxi-item>
      <div *dxTemplate="let content of 'checkInfoTemplate'">
         <div class="dx-card">
            <div class="container">
                  <div class="row">
                     <div class="col">
                        <span>
                           <h5>{{ selectedCheck.payee }}</h5>
                        </span>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-6">
                        <h5>Check#: {{selectedCheck.checkno}}</h5>
                     </div>
                     <div class="col-6">
                        <h5>${{selectedCheck.amount}}</h5>
                     </div>
                  </div>
               </div>
         
         </div>
      </div>
      <dxi-item dataField="voidreasonid" editorType="dxSelectBox" [visible]="!!!selectedCheck.canVoid"
      [editorOptions]="{ items: voidReasons, displayExpr:'desc', valueExpr:'id', readOnly:!!!selectedCheck.canVoid }">
      <dxo-label text="Void Reason"></dxo-label>   
   </dxi-item>
      <dxi-item dataField="voidinfo" editorType="dxTextBox" [visible]="!!!selectedCheck.canVoid"
      [editorOptions]="{readOnly:!!!selectedCheck.canVoid}">
      <dxo-label text="Other reasons"></dxo-label>
      </dxi-item>
   </dx-form>
   <form action="#" (submit)="onVoidFormSubmit($event)" >
      <dx-form [formData]="voidDecisionBox" [visible]="!!selectedCheck.canVoid">
      <dxi-item dataField="voidreasonid"  editorType="dxSelectBox"
         [editorOptions]="{ items: voidReasons, displayExpr:'desc', valueExpr:'id'}">
         <dxo-label text="Void Reason"></dxo-label> 
      </dxi-item>
      <dxi-item dataField="voidinfo" editorType="dxTextBox" [editorOptions]="{}">
         <dxo-label text="Other Reasons"></dxo-label>
      </dxi-item>
      <dxi-item [template]="'actionTemplate'">
         <dxo-label [visible]="false"></dxo-label>
      </dxi-item>
      <div *dxTemplate="let content of 'actionTemplate'">
         <div class="group-container">
            <dx-button class="right-align-buttons" type="normal" text="Cancel" (onClick)="voidBoxVisible=false">
            </dx-button>
            <dx-button class="right-align-buttons" type="success" text="Void"
            [useSubmitBehavior]="true" [visible]="!!selectedCheck.canVoid" >
            </dx-button>
         </div>
      </div>
      </dx-form>
   </form>
</div>
</dx-popup>
<div *ngIf = "!useNativePDF">
   <dx-popup
       title="Invoice"
       [(visible)]="invoiceBoxVisible">
       <div *dxTemplate="let data of 'content'">
         <ng2-pdfjs-viewer  
         #pdfViewer
       [externalWindow]="false"
         [openFile]="false"
         [viewBookmark]="false"
         [download]="true"
         [find]="false"
         [rotatecw]="true"
         [fullScreen]="false"
         [rotateccw]="true"
         [diagnosticLogs]="false"
         (onBeforePrint)="beforePrint()" 
     ></ng2-pdfjs-viewer>
       </div>
   </dx-popup>
   </div>
   <div *ngIf="useNativePDF"> 
   <dx-popup
      id="invoiceView"
       title="Document Viewer"
       (onHidden)="popup_hidden($event)"
       [(visible)]="invoiceBoxVisible">
       
       <div *dxTemplate="let data of 'content'">
        <div class="dx-card">
           <dx-form
            labelLocation="left"
           >
              <dxi-item dataField="Viewing Invoice:">
               <dx-select-box
               [items]="selectedCheck.invoiceData"
               placeholder="Choose Invoice"
               [value] = "selectedCheck.invoiceData[0]"
               (onItemClick) = "invoiceSelected($event)"
            ></dx-select-box>
              </dxi-item>
           </dx-form>
            
      </div>
      <dx-load-indicator class='button-indicator' style="text-align:center;display:block;position:fixed;right: 50%; "
            [visible]="loadIndicatorVisible">
      </dx-load-indicator>
  
         <object #iframePDF type="application/pdf" width="100%" height="0">
            <p><b>Error</b>: This browser does not support this file. 
            </object>
            <div style="width: auto;height: 100%;overflow-y: scroll;overflow-x: hidden;white-space: nowrap;">
            <img #invoiceImage width="100%"/>
            </div>
           
       </div>
   </dx-popup>
   </div>

   <dx-popup
       title="Check Viewer"
       (onHidden)="popup_hidden($event)"
       [(visible)]="checkViewerBoxVisible">
       
       <div *dxTemplate="let data of 'content'">
         <dx-load-indicator class='button-indicator' style="text-align:center;display:block;position:fixed;right: 50%; "
               [visible]="loadIndicatorVisible">
         </dx-load-indicator>
         <object #iframeCheckPDF type="application/pdf" width="100%" height="0">
            <p><b>Error</b>: This browser does not support this file. 
            </object>
            <div style="width: auto;height: 100%;overflow-y: scroll;overflow-x: hidden;white-space: nowrap;">
            </div>
           
       </div>
   </dx-popup>
   
import { AuthService } from './../auth.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { CheckServiceService } from '../check-service.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-check-report',
  templateUrl: './check-report.component.html',
  styleUrls: ['./check-report.component.css'],
})
export class CheckReportComponent implements OnInit {
  @ViewChild('pdfViewer') public pdfViewer!: any;
  @ViewChild('iframePDF') public iframePDF!: ElementRef;
  @ViewChild('invoiceImage') public invoiceImage!: ElementRef;
  checks: any;
  pdfSrc: any;
  user: any;
  useNativePDF = true;
  selectedCheck: any;
  invoiceBoxVisible = false;
  loadIndicatorVisible = false;
  actions = [{ id: 4, text: 'Invoice', icon: 'doc' }];

  constructor(
    private service: CheckServiceService,
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.showInvoiceBox = this.showInvoiceBox.bind(this);
  }

  ngOnInit(): void {
    this.user = this.authService.user;
    this.service.getChecks({ user_id: this.user.user_id }).subscribe(
      (response: any) => {
        this.checks = response.checks;
        console.log(response);
      },
      (error) => {
        console.error('error caught in component');
        //this.errorMsg = error;
        throw error;
      }
    );
  }
  dataGridPrepared(e: any) {
    if (e.rowType === 'data') {
      console.log(e);
      if (e.data.voidreasonid) {
        e.rowElement.style.textDecoration = 'line-through';
        e.rowElement.style.fontWeight = 'normal';
        e.rowElement.style.color = '#777';
      }
      if (!e.data.voidreasonid && !e.data.isConfirm) {
        e.rowElement.style.fontWeight = 'bold';
      }
    }
  }
  showInvoiceBox(row: any, event: any) {
    this.selectedCheck = row.data;

    if (event.itemData.text == 'Print') {
      console.log('Print');
      console.log(row);
      this.pdfSrc =
        'https://check.quicktrackinc.com/api/printcheck?checkid=' + row.data.id;
      this.service.downloadFile(this.pdfSrc).subscribe((res: any) => {
        this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
        this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
      });
      this.invoiceBoxVisible = true;
      notify(
        {
          message: 'Loading the check...',
          position: {
            my: 'center center',
            at: 'center center',
          },
        },
        'success',
        3000
      );
    }

    if (event.itemData.text == 'Invoice') {
      console.log('Invoice');
      this.pdfSrc =
        'https://check.quicktrackinc.com/api/download?blob=' +
        encodeURIComponent(row.data.invoiceData[0]);
      let fileType = row.data.invoiceData[0].split('.').pop();
      let fileMIME = '';
      if (fileType === 'pdf') {
        fileMIME = 'application/' + fileType;
      } else {
        fileMIME = 'image/' + fileType;
      }

      if (this.invoiceImage) {
        this.invoiceImage.nativeElement.style = 'visibility: hidden;';
      }
      if (this.iframePDF) {
        this.iframePDF.nativeElement.style = 'visibility: hidden; height:0;';
      }
      this.loadIndicatorVisible = true;
      this.downloadFile(this.pdfSrc).subscribe((res: any) => {
        if (this.useNativePDF) {
          let pdfobj = new Blob([res], { type: fileMIME });
          //this.iframePDF.nativeElement.src = URL.createObjectURL(pdfobj);
          if (fileType === 'pdf' || fileType === 'PDF') {
            console.log('PDF file Received');
            this.iframePDF.nativeElement.type = fileMIME;
            this.iframePDF.nativeElement.style =
              'visibility: visible; height:100%;';
            this.iframePDF.nativeElement.data = URL.createObjectURL(pdfobj);
          } else {
            console.log('non pdf file Received');
            this.invoiceImage.nativeElement.style = 'visibility:visible;';
            this.invoiceImage.nativeElement.src = URL.createObjectURL(pdfobj);
          }
          this.loadIndicatorVisible = false;
        } else {
          this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
          this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
        }
      });

      this.invoiceBoxVisible = true;
      notify(
        {
          message: 'Check confirmed!',
          position: {
            my: 'center center',
            at: 'center center',
          },
        },
        'success',
        3000
      );
    }
  }
  invoiceSelected(e: any) {
    console.log(e);
    this.loadIndicatorVisible = true;
    this.pdfSrc =
      'https://check.quicktrackinc.com/api/download?blob=' +
      encodeURIComponent(e.itemData);
    let fileType = e.itemData.split('.').pop();
    let fileMIME = '';
    if (fileType === 'pdf') {
      fileMIME = 'application/' + fileType;
    } else {
      fileMIME = 'image/' + fileType;
    }

    if (this.invoiceImage) {
      this.invoiceImage.nativeElement.style = 'visibility: hidden;';
    }
    if (this.iframePDF) {
      this.iframePDF.nativeElement.style = 'visibility: hidden; height:0;';
    }

    this.downloadFile(this.pdfSrc).subscribe((res: any) => {
      if (this.useNativePDF) {
        let pdfobj = new Blob([res], { type: fileMIME });
        //this.iframePDF.nativeElement.src = URL.createObjectURL(pdfobj);
        if (fileType === 'pdf' || fileType === 'PDF') {
          console.log('PDF file Received');
          this.iframePDF.nativeElement.type = fileMIME;
          this.iframePDF.nativeElement.style =
            'visibility: visible; height:100%;';
          this.iframePDF.nativeElement.data = URL.createObjectURL(pdfobj);
        } else {
          console.log('non pdf file Received');
          this.invoiceImage.nativeElement.style = 'visibility:visible;';
          this.invoiceImage.nativeElement.src = URL.createObjectURL(pdfobj);
        }
        this.loadIndicatorVisible = false;
      } else {
        this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
        this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
      }
    });
  }

  popup_hidden(e: any) {
    // Handler of the "hidden" event
    if (this.iframePDF) {
      this.iframePDF.nativeElement.data = '';
    }
  }

  private downloadFile(url: string): any {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((result: any) => {
        return result;
      })
    );
  }
}

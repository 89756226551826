
    <div class="container h-100">
        <div class="row align-items-center h-100">
            <div class="col-6 mx-auto">
                <div class="container dx-card h-100 border-primary justify-content-center py-3">
                    <div>
                        <h2 class="text-center">QuickTrack Login</h2>
                        <form #form method="post" action="https://check.quicktrackinc.com/api/authenticate" id="form" (submit)="login()">
                            <dx-form
                                [formData]="user">
                                <dxi-item dataField="username"></dxi-item>
                                <dxi-item dataField="password" [editorOptions]="{'mode':'password'}"></dxi-item>
                               
                                <dxi-item 
                                    itemType="button"
                                    horizontalAlignment="left"
                                    [buttonOptions]="buttonOptions">
                                </dxi-item>
                                
                            </dx-form>
                          </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
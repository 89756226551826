import { Component, OnInit } from '@angular/core';
import { CheckServiceService } from '../check-service.service';

@Component({
  selector: 'app-add-bank',
  templateUrl: './add-bank.component.html',
  styleUrls: ['./add-bank.component.css']
})
export class AddBankComponent implements OnInit {

  bank:any = {};
  allstores:any = {};
  allcorporations: any = [];
  allbanknames: any;
  selectedBankID:any;
  selectedCorporationID:any;
  newCorporationVisible = false;
  addNewCorporationButtonOption = {
    type: 'normal', 
    text:"Add New", 
    onClick: () =>{
      this.bank.corporation = {};
      this.newCorporationVisible = true;
    }   
  };

  constructor(private service: CheckServiceService) { 
  }

  ngOnInit(): void {
   this.service.getAllBankNames().subscribe((res) =>{
      this.allbanknames = res.banks;
    });
    this.service.getAllCorporations().subscribe((res) =>{
      this.allcorporations = res.corporations;
    });
  }

  onFormSubmit(event:any) {
    
    this.bank.corporationid = this.bank.corporation.id;
    console.log(this.bank);
    this.service.setBank(this.bank).subscribe((res) =>{
      this.bank.bankid = res.bankid;
      console.log(this.bank);
    })
    
    console.log("Form submitted");
  }
  
  corporationChangedHandler(corporation: any) {
    this.newCorporationVisible = false;
    console.log('add currently added corp to the corp list');
    this.allcorporations.push(corporation);
    
    console.log("set id of newly added corp: " + corporation.id);
    
    //console.log(bank);
  }

}

import { Component, OnInit } from '@angular/core';
import { custom } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { AuthService } from '../auth.service';
import { CheckServiceService } from '../check-service.service';

@Component({
  selector: 'app-set-vendor',
  templateUrl: './set-vendor.component.html',
  styleUrls: ['./set-vendor.component.css']
})
export class SetVendorComponent implements OnInit {
  buttonOptions: any;
  vendor:any = {};
  allvendors:any = {};
  banks: any;
  assignedBanks: any =[];
  availableBanks: any = [];
  selectedBanks: any = [];
  selectedAssignedBanks: any = [];

  constructor(private service: CheckServiceService, private authService: AuthService) {
    this.vendorNameValidation = this.vendorNameValidation.bind(this);
    this.vendorTypeValidate = this.vendorTypeValidate.bind(this);
   }

  ngOnInit(): void {

    this.buttonOptions = {
      text: "Submit",
      type: "success",
      useSubmitBehavior: true
    }
    this.service.getBanksByUser()
    .subscribe(
      (response: any) => {
        this.banks = response.banks;
        this.availableBanks = this.banks;
        console.log(response);
      },  
      (error) => {                          
        console.error('error caught in component')
        //this.errorMsg = error;
        throw error;
      });

      this.service.getAllVendors()
    .subscribe(
      (response: any) => {
        this.allvendors = response.vendors;
        console.log(response);
      },  
      (error) => {                          
        console.error('error caught in component')
        //this.errorMsg = error;
        throw error;
      });
  }
  vendorNameValidation(params:any) {
    let val = {vendor_name: params.value};
    return new Promise((resolve,reject) => {
      this.service.checkVendorByName(val).toPromise()
        .then((res:any)=> {
          if(!res.isValid) {
            let myDialog = custom({
              title: "Vendor Exists",
              messageHtml: "<p>" + params.value + " already exist in our system.</p><p>It's data will be loaded.</p>",
              buttons: [
                {        
                  text: "OK",  
                  onClick: (e) => {
                    this.service.getVendorDataByName(val).subscribe(
                      (response:any) => {
                        console.log(response.vendor);
                          this.vendor = response.vendor;
                          this.assignedBanks = this.vendor.banks;
                          this.availableBanks = this.banks;
                          this.availableBanks = this.availableBanks.filter((ar:any) => !this.assignedBanks.find((rm:any) => (rm.bankID === ar.bankID) ));
                         
                      },
                      error=>{
                        console.log(error);
                      }
                    );
                  }          
                } 
             
              ]
            });
          if(!this.vendor.id) {
            myDialog.show();
          }
          }
          res.isValid?resolve(res.message): reject(res.message);
        })
    });
}

vendorInfoRetrieve(event:any) {
  console.log(event);
  if(!event.selectedItem) {
    return;
  }
  let val = {vendor_name: event.selectedItem.vendor_name, vendor_id: event.selectedItem.id};
  //let val = {vendor_name: event.itemData.vendor_name, vendor_id: event.itemData.id};
  console.log(val);
  return new Promise((resolve,reject) => {
    this.service.checkVendorByName(val).toPromise()
      .then((res:any)=> {
        notify({
          message: "Loading " + val.vendor_name + "'s data.",
          position: {
              my: "center center",
              at: "center center"
          }
      }, "success", 3000);

        this.service.getVendorDataByName(val).subscribe(
          (response:any) => {
            console.log(response.vendor);
              this.vendor = response.vendor;
              this.assignedBanks = this.vendor.banks;
              this.availableBanks = this.banks;
              this.availableBanks = this.availableBanks.filter((ar:any) => !this.assignedBanks.find((rm:any) => (rm.bankID === ar.bankID) ));
             
          },
          error=>{
            console.log(error);
          }
        );
        res.isValid?resolve(res.message): reject(res.message);
      })
  });
}
  vendorTypeValidate() {
    return this.vendor.isExpenses || this.vendor.isMerchandise || this.vendor.isEmployee;
  }

  assignSelectedBanks(e:any) {
    if(this.selectedBanks.length<1) {
      return;
    }
    this.assignedBanks.push.apply(this.assignedBanks, this.selectedBanks);
    //this.availableBanks = this.banks.filter((item: any)=>!this.assignedBanks.includes(item));
    this.availableBanks = this.banks.filter((ar:any) => !this.assignedBanks.find((rm:any) => (rm.bankID === ar.bankID) ));
    this.selectedAssignedBanks =[];
    this.selectedBanks = [];
    this.availableBanks.sort();
    this.assignedBanks.sort();
  }
  assignAllBanks(e:any) {
    this.assignedBanks.push.apply(this.assignedBanks, this.availableBanks);
    //this.availableBanks = this.banks.filter((item: any)=>!this.assignedBanks.includes(item));
    this.availableBanks = this.banks.filter((ar:any) => !this.assignedBanks.find((rm:any) => (rm.bankID === ar.bankID) ));
    this.selectedAssignedBanks =[];
    this.selectedBanks = [];
    this.availableBanks.sort();
    this.assignedBanks.sort();
  }
  
  unAssignSelectedBanks(e:any) {
    if(this.selectedAssignedBanks.length<1) {
      return;
    }
    
    //filter out selected banks from assignedbanks list
   // this.assignedBanks = this.assignedBanks.filter((item:any)=>!this.selectedAssignedBanks.includes(item));
    this.assignedBanks = this.assignedBanks.filter((ar:any) => !this.selectedAssignedBanks.find((rm:any) => (rm.bankID === ar.bankID) ));
    //update available banks and remove asignedbanks
    this.availableBanks = this.banks;
    this.availableBanks = this.availableBanks.filter((ar:any) => !this.assignedBanks.find((rm:any) => (rm.bankID === ar.bankID) ));
    this.selectedAssignedBanks =[];
    this.selectedBanks = [];
    this.availableBanks.sort();
    this.assignedBanks.sort();
  }
  unAssignAllBanks(e:any) {

    this.assignedBanks = [];
    this.availableBanks = this.banks;
    this.selectedAssignedBanks =[];
    this.selectedBanks = [];
    this.availableBanks.sort();
    this.assignedBanks.sort();
  }
  onFormSubmit(event:any) {
    event.preventDefault();
   
    this.vendor.banks = this.assignedBanks.map((obj:any) => obj.bankID);
    console.log(this.vendor.banks);
    console.log(this.vendor);

    this.service.setVendor(this.vendor)
      .subscribe(
        (response: any)=>{
          console.log(response);
          this.vendor.id = response.vendorid;
          console.log(this.vendor);
          notify({
            message: this.vendor.vendor_name + " has been added.",
            position: {
                my: "center center",
                at: "center center"
            }
        }, "success", 3000);


        }, error=>{
          console.log(error);
        });
        notify({
          message: "Adding Vendor",
          position: {
              my: "center center",
              at: "center center"
          }
      }, "success", 3000);

      
  }

}

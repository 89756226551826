  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <a class="navbar-brand" >QT Office</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item" routerLinkActive="active" *ngIf="userRole<=7">
            <a class="nav-link" routerLink="/main/check">Write Checks</a>
          </li>
          <li class="nav-item" routerLinkActive="active" *ngIf="userRole<=3">
            <a class="nav-link" routerLink="/main/report">Reports</a>
          </li>
          <li class="nav-item" routerLinkActive="active" *ngIf="userRole<=3">
            <a class="nav-link" routerLink="/main/vendor">Add Vendor</a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/main/user" *ngIf="userRole<=1">Add User</a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/main/bank" *ngIf="userRole<=1">Add Bank</a>
          </li>
          
        </ul>
      </div>

      <div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link disabled" href="#">{{username}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="logOut()" href="#">Logout</a>
          </li>
        </ul>
    </div>
    </nav>
<div class="container-xxl m-3 p-1">
    <form #form method="post" id="form" (submit)="onFormSubmit($event)">
    <div class="row">
       <div class="col-6">
          <div class="container dx-card">
            
             <dx-form
             [(formData)]="corporation"
             labelLocation="left"
             [showValidationSummary]="true"
             validationGroup = "corporationValidationGroup"
             >
             <dxi-item dataField="corporation_name">
                <dxi-validation-rule
                   type="required"
                   message="Corporation name is required">
                </dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="owner"></dxi-item>
            <dxi-item dataField="ein"></dxi-item>
           
           
             <dxi-item [template]="'actionTemplate'">
                <dxo-label [visible]="false"></dxo-label>
             </dxi-item>
             <div *dxTemplate="let content of 'actionTemplate'">
                <div class="group-container">
                   <dx-button class="right-align-buttons" type="normal" text="Cancel" >
                   </dx-button>
                   <dx-button class="right-align-buttons" type="success" text="Add Company"
                   [useSubmitBehavior]="true" validationGroup="corporationValidationGroup" >
                   </dx-button>
                </div>
             </div>
             </dx-form>
             
          </div>
       </div>
       <div class="col-6">
          <div class="container dx-card">
            
          </div>
       </div>
    </div>
 </form>
 </div>
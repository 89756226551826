import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CheckServiceService {
  apiUrl = 'https://check.quicktrackinc.com/api';
  //apiUrl="http://localhost:31235/api";
  getVendors() {
    return this.http.get<any>(this.apiUrl + '/getcheckvendors').pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }

  getBanksByUser() {
    return this.http.get<any>(this.apiUrl + '/getbanksbyuser').pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }
  getAllBanks() {
    return this.http.get<any>(this.apiUrl + '/getallbanks').pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }
  getAllBankNames() {
    return this.http.get<any>(this.apiUrl + '/getallbanknames').pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }
  getAllCorporations() {
    return this.http.get<any>(this.apiUrl + '/getallcorporations').pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }
  getAllBankStores() {
    return this.http.get<any>(this.apiUrl + '/getallbankstores').pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }
  createCheck(resource: any) {
    console.log('resource sent');
    console.log(resource);
    return this.http.post<any>(this.apiUrl + '/createcheck', resource).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }

  createNonCheck(resource: any) {
    console.log('resource sent');
    console.log(resource);
    return this.http.post<any>(this.apiUrl + '/createnoncheck', resource).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }
  getVendorsByBank(resource: any) {
    console.log('resource sent');
    console.log(resource);
    return this.http
      .post<any>(this.apiUrl + '/getvendorsbybank', resource)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          console.log('error caught in service');
          console.error(err);
          return throwError(err);
        })
      );
  }
  setBank(resource: any) {
    console.log('resource sent');
    console.log(resource);
    return this.http.post<any>(this.apiUrl + '/setbank', resource).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }

  setCorporation(resource: any) {
    console.log('resource sent');
    console.log(resource);
    return this.http.post<any>(this.apiUrl + '/setcorporation', resource).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }
  setVendor(resource: any) {
    console.log('resource sent');
    console.log(resource);
    return this.http.post<any>(this.apiUrl + '/setvendor', resource).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }
  setUser(resource: any) {
    console.log('resource sent');
    console.log(resource);
    return this.http.post<any>(this.apiUrl + '/setuser', resource).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }
  voidCheck(resource: any) {
    console.log('resource sent');
    console.log(resource);
    return this.http.post<any>(this.apiUrl + '/voidcheck', resource).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }

  confirmCheck(resource: any) {
    console.log('resource sent');
    console.log(resource);
    return this.http.post<any>(this.apiUrl + '/confirmcheck', resource).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }

  getRecentChecks() {
    return this.http.get<any>(this.apiUrl + '/getrecentchecks').pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }
  getAllVendors() {
    return this.http.get<any>(this.apiUrl + '/getallvendors').pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }
  getAllUsers() {
    return this.http.get<any>(this.apiUrl + '/getallusers').pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }
  getChecks(resource: any) {
    return this.http.post<any>(this.apiUrl + '/getchecks', resource).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }

  checkVendorByName(resource: any) {
    return this.http.post<any>(this.apiUrl + '/getvendorbyname', resource).pipe(
      map((res) => {
        console.log(res);
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }
  checkUserByUsername(resource: any) {
    return this.http.post<any>(this.apiUrl + '/getuserbyname', resource).pipe(
      map((res) => {
        console.log(res);
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }
  getUserDataByUsername(resource: any) {
    return this.http.post<any>(this.apiUrl + '/getuserdata', resource).pipe(
      map((res) => {
        console.log(res);
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }
  getVendorDataByName(resource: any) {
    return this.http.post<any>(this.apiUrl + '/getvendordata', resource).pipe(
      map((res) => {
        console.log(res);
        return res;
      }),
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err);
      })
    );
  }

  downloadFile(url: string): any {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((result: any) => {
        return result;
      })
    );
  }
  constructor(private http: HttpClient) {}
}

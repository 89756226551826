<div class="container-xxl m-3 p-1">
    <div class="row">
        <div class="col">
            <dx-data-grid id="dataGrid"
            [dataSource]="checks"
            [rowAlternationEnabled]="true"
            [allowColumnResizing]="true"
            keyExpr="id"
            [allowColumnReordering]="true"
            (onRowPrepared) = "dataGridPrepared($event)"
            >
            <dxo-filter-row 
            [visible]="true" 
            >
        </dxo-filter-row>
        <dxo-header-filter 
            [visible]="true"></dxo-header-filter>
        <dxo-search-panel 
            [visible]="true" 
            [width]="240" 
            placeholder="Search..."></dxo-search-panel>
            <dxo-paging [pageSize]="20"></dxo-paging>
            <dxo-pager 
                [showPageSizeSelector]="true"
                [allowedPageSizes]="[10, 20]"
                [showInfo]="true">
            </dxo-pager>
            <dxo-export [enabled]="true" [allowExportSelectedData]="false"></dxo-export>
        <dxi-column dataField="created_date" dataType="date" width="80"></dxi-column>
        <dxi-column dataField="checkno" width="80"></dxi-column>
        <dxi-column dataField ="dba"></dxi-column>
        <dxi-column dataField="payee" maxWidth="200"></dxi-column>
        <dxi-column dataField="amount" [format]="{ style: 'currency', currency: 'USD'}"></dxi-column>
        <dxi-column dataField="memo"></dxi-column>
        <dxi-column dataField="display_name" caption="User"></dxi-column>
        <dxi-column dataField="isConfirm" caption="Confirmation"></dxi-column>
        <dxi-column dataField="status" cellTemplate="cellTemplate"></dxi-column>
        <div *dxTemplate="let data of 'cellTemplate'">
            <dx-drop-down-button
            text="Options"
            [items]="actions"
            keyExpr="id"
            displayExpr="text"
            width="80%"
            (onItemClick)="showInvoiceBox(data, $event)">
            </dx-drop-down-button>
        
        </div>
        </dx-data-grid>
        </div>
    </div>
</div>
<div *ngIf = "!useNativePDF">
<dx-popup
    title="Invoice"
    [dragEnabled]="false"
    [resizeEnabled]="false"
    [(visible)]="invoiceBoxVisible">
    <div *dxTemplate="let data of 'content'">
      <ng2-pdfjs-viewer  
      #pdfViewer
    [externalWindow]="false"
      [openFile]="false"
      [viewBookmark]="false"
      [download]="true"
      [find]="false"
      [rotatecw]="true"
      [fullScreen]="false"
      [rotateccw]="true"
      [diagnosticLogs]="false"
  ></ng2-pdfjs-viewer>
    </div>
</dx-popup>
</div>
<div *ngIf="useNativePDF"> 
    <dx-popup
    title="Document Viewer"
    (onHidden)="popup_hidden($event)"
    [(visible)]="invoiceBoxVisible">
    
    <div *dxTemplate="let data of 'content'">
     <div class="dx-card">
        <dx-form
         labelLocation="left"
        >
           <dxi-item dataField="Viewing Invoice:">
            <dx-select-box
            [items]="selectedCheck.invoiceData"
            placeholder="Choose Invoice"
            [value] = "selectedCheck.invoiceData[0]"
            (onItemClick) = "invoiceSelected($event)"
         ></dx-select-box>
           </dxi-item>
        </dx-form>
         
   </div>
   <dx-load-indicator class='button-indicator' style="text-align:center;display:block;position:fixed;right: 50%; "
        [visible]="loadIndicatorVisible">
    </dx-load-indicator>
     
     
      <object #iframePDF type="application/pdf" width="100%" height="0">
         <p><b>Error</b>: This browser does not support this file. 
         </object>
         <div style="width: auto;height: 100%;overflow-y: scroll;overflow-x: hidden;white-space: nowrap;">
         <img #invoiceImage width="100%"/>
         </div>
        
    </div>
</dx-popup>
</div>
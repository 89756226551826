import { AuthInterceptor } from './auth.interceptor';
import { CheckServiceService } from './check-service.service';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { CheckWriterComponent } from './check-writer.component';
import { DxAutocompleteModule, DxButtonModule, DxDataGridModule, DxDropDownButtonModule, DxFileUploaderModule, DxFormModule, DxListModule, DxNumberBoxModule, DxPopupModule, DxRadioGroupModule, DxSelectBoxModule, DxTextBoxModule, DxValidatorModule, DxLoadIndicatorModule, DxLoadPanelModule, DxButtonGroupModule } from 'devextreme-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NavComponent } from './nav/nav.component';
import { LoginComponent } from './login/login.component';
import { RootComponent } from './root/root.component';
import { CheckReportComponent } from './check-report/check-report.component';
import { SetVendorComponent } from './set-vendor/set-vendor.component';
import { SetUserComponent } from './set-user/set-user.component';
import { AddCorporationComponent } from './add-corporation/add-corporation.component';
import { AddBankComponent } from './add-bank/add-bank.component';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { NO_ERRORS_SCHEMA } from '@angular/core';


@NgModule({
  declarations: [
    NavComponent,
    LoginComponent,
    RootComponent,
    CheckWriterComponent,
    CheckReportComponent,
    SetVendorComponent,
    SetUserComponent,
    AddCorporationComponent,
    AddBankComponent,
    LoginLayoutComponent,
    MainLayoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DxFormModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxDataGridModule,
    DxButtonModule,
    DxPopupModule,
    DxDropDownButtonModule,
    DxFileUploaderModule,
    DxAutocompleteModule,
    DxNumberBoxModule,
    DxListModule,
    FormsModule,
    HttpClientModule,
    DxValidatorModule,
    DxRadioGroupModule,
    PdfJsViewerModule,
    DxLoadIndicatorModule,
    DxButtonGroupModule
  ],
  providers: [CheckServiceService, 
        HttpClient, 
        {
          provide:HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
        }
      ],
  bootstrap: [RootComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }

import { Router } from '@angular/router';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private router:Router) {}
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authToken = this.authService.getToken();
        req=req.clone({
            setHeaders: {
                Authorization: "jwt "+ authToken
            }
        });

        return next.handle(req).pipe( tap(() => {},
        (err:any)=> {
            if(err instanceof HttpErrorResponse) {
                if(err.status !=401) {
                    return;
                }
                this.router.navigateByUrl('/login');
            }
        }
        ));
        //throw new Error('Method not implemented.');
    }
}
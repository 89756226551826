<div class="container-xxl m-3 p-1">
   <form #form method="post" id="form" (submit)="onFormSubmit($event)">
   <div class="row">
      <div class="col-4">
         <div class="container dx-card py-2">
            <h3>Vendor Details</h3>
            <dx-form
            [(formData)]="vendor"
            labelLocation="left"
            [showValidationSummary]="true"
            validationGroup = "vendorData"
            >
            <dxi-item dataField="vendor_name">
               <dx-autocomplete 
                  [dataSource]="allvendors"
                  [showClearButton]= "false"
                  valueExpr= "vendor_name"
                  [searchTimeout]= "200"
                  [(value)] = "vendor.vendor_name"
                  (onSelectionChanged) = "vendorInfoRetrieve($event)"
               >

               </dx-autocomplete>
               <dxi-validation-rule
                  type="required"
                  message="Vendor name is required">
               </dxi-validation-rule>
                <!-- <dxi-validation-rule type="custom" message="Vendor already exists" [validationCallback]="vendorNameValidation"></dxi-validation-rule> -->
            </dxi-item>
            <dxi-item itemType="group" caption="Vendor Type" colCount="3">
               <dxi-item dataField="isMerchandise" editorType="dxCheckBox" ><dxo-label text="Merchandise"></dxo-label>
                  <dxi-validation-rule type="custom" [reevaluate]="true" message="Choose vendor type(s)" [validationCallback]="vendorTypeValidate"></dxi-validation-rule>
               </dxi-item>
               <dxi-item dataField="isExpenses" editorType="dxCheckBox"><dxo-label text="Expense"></dxo-label>
                  <dxi-validation-rule type="custom" [reevaluate]="true" message="Choose vendor type(s)" [validationCallback]="vendorTypeValidate"></dxi-validation-rule>
               </dxi-item>
               <dxi-item dataField="isEmployee" editorType="dxCheckBox"><dxo-label text="Employee"></dxo-label>
                  <dxi-validation-rule type="custom" [reevaluate]="true" message="Choose vendor type(s)" [validationCallback]="vendorTypeValidate"></dxi-validation-rule>
               </dxi-item>
            </dxi-item>
            <dxi-item dataField="description">
               <dxi-validation-rule
                  type="required"
                  message="Description is required">
               </dxi-validation-rule>
            </dxi-item>
          
            <dxi-item [template]="'actionTemplate'">
               <dxo-label [visible]="false"></dxo-label>
            </dxi-item>
            <div *dxTemplate="let content of 'actionTemplate'">
               <div class="group-container">
                  <dx-button class="right-align-buttons" type="normal" text="Cancel" >
                  </dx-button>
                  <dx-button class="right-align-buttons" type="success" text="Update"
                  [useSubmitBehavior]="true" validationGroup="vendorData" >
                  </dx-button>
               </div>
            </div>
            </dx-form>
            
         </div>
      </div>
      <div class="col-8">
         <div class="container dx-card py-2">
            <div class="row">
               <div class="col">
                  <h3>Unassigned Accounts</h3>
                  <dx-list 
                     #list
                     [dataSource]="availableBanks"
                     [height]="400"
                     [selectionMode]="'all'"
                     [selectAllMode]="'allPages'"
                     [showSelectionControls]="true"
                     [(selectedItems)]="selectedBanks"
                     [searchEnabled]="true"
                     searchExpr="dba"
                     keyExpr = "bankID"
                     searchMode="contains">
                     <div *dxTemplate="let data of 'item'">
                        <div class="row">
                           <div class="col">{{data.dba}}</div>
                           <div class="col text-right"><small>{{data.accountName}}</small></div>
                        </div>
                        <div class="row">
                           <div class="col text-right"><small>{{data.bankName}}</small></div>
                        </div>
                     </div>
                     </dx-list>
               </div>
               <div class="col-1 align-self-center pick-list-control">
                  <dx-button
                     icon="chevronright"                     
                     type="success"
                     stylingMode="text"
                     (onClick)="assignSelectedBanks($event)"
                    >
                  </dx-button>
                  <dx-button
                     icon="chevrondoubleright"
                     type="success"
                     stylingMode="text"
                     (onClick)="assignAllBanks($event)"
                    >
                  </dx-button>
                  <dx-button
                     icon="chevronleft"                    
                     type="success"
                     stylingMode="text"
                     (onClick)="unAssignSelectedBanks($event)"
                    >
                  </dx-button>
                  <dx-button
                     icon="chevrondoubleleft"
                     type="success"
                     stylingMode="text"
                     (onClick)="unAssignAllBanks($event)"
                    >
                  </dx-button>
                </div>
               <div class="col">
                  <h3>Assigned Accounts</h3>
                  <dx-list 
                  #list
                  [dataSource]="assignedBanks"
                  [height]="400"
                  [selectionMode]="'all'"
                  [selectAllMode]="'allPages'"
                  [showSelectionControls]="true"
                  [(selectedItems)]="selectedAssignedBanks"
                  [searchEnabled]="true"
                  searchExpr="dba"
                  keyExpr = "bankID"
                  searchMode="contains">
                  <div *dxTemplate="let data of 'item'">
                    <div class="row">
                       <div class="col">{{data.dba}}</div>
                       <div class="col text-right"><small>{{data.accountName}}</small></div>
                    </div>
                    <div class="row">
                       <div class="col text-right"><small>{{data.bankName}}</small></div>
                    </div>
                  </div>
                  </dx-list>
               </div>
            </div>
         </div>
      </div>
   </div>
</form>
</div>
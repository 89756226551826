import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  constructor(private authService: AuthService) { }
  userRole: any;
  username: any;
  ngOnInit(): void {
    this.userRole = this.authService.user.role;
    this.username = this.authService.user.display_name;
  }
  logOut() {
    this.authService.doLogout();
  }
}

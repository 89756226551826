import { AuthService } from './../auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: any = {};
  buttonOptions = {
    text: "Login",
    type: "success",
    useSubmitBehavior: true
  }
  constructor(private authService: AuthService, private router:Router) { 
    
  }

  login() {
  
    if(this.user.username && this.user.password) {
      this.authService.login(this.user.username, this.user.password)
      .subscribe(
        (res: any) => {
          this.router.navigateByUrl('main/check');
        },  
        (error) => {                          
          console.error('error caught in component');
         
          if(error.status == 401) {
          notify(
            {
              message: "Invalid Login!",
              position: {
                my: "center center",
                at: "center center"
              }
            }, 
            "Error", 3000
          );

        } else {
          notify(
            {
              message: "Unknown Error. Try Again!",
              position: {
                my: "center center",
                at: "center center"
              }
            }, 
            "Error", 3000
          );
        }
          throw error;
        
        
        });
  
     


    }
  }
  logout() {
    this.authService.doLogout();
    //this.router.navigate(['log-in']);
    this.router.navigateByUrl('login');
  }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { custom } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { CheckServiceService } from '../check-service.service';

@Component({
  selector: 'app-set-user',
  templateUrl: './set-user.component.html',
  styleUrls: ['./set-user.component.css']
})
export class SetUserComponent implements OnInit {
  buttonOptions: any;
  user:any = {};
  allusers:any = {};
  banks: any =[];
  availableBanks: any =[];
  assignedBanks: any =[];
  selectedBanks: any = [];
  selectedAssignedBanks: any = [];
  userRoles: any = [
    {value:4 , name: 'User'},
    {value:2 , name: 'Admin'},
    {value:1 , name: 'Super Admin'},
  ];
 
  constructor(private service: CheckServiceService) {
    this.usernameValidation = this.usernameValidation.bind(this);
    this.userTypeValidate = this.userTypeValidate.bind(this);
   }

  ngOnInit(): void {
    this.buttonOptions = {
      text: "Submit",
      type: "success",
      useSubmitBehavior: true
    }
      this.service.getAllBanks()
      .subscribe(
        (response: any) => {
          this.banks = response.banks;
          this.availableBanks= this.banks;
          console.log(response);
        },  
        (error) => {                          
          console.error('error caught in component')
          //this.errorMsg = error;
          throw error;
        });

      this.service.getAllUsers()
    .subscribe(
      (response: any) => {
        this.allusers = response.users;
        console.log(response);
      },  
      (error) => {                          
        console.error('error caught in component')
        //this.errorMsg = error;
        throw error;
      });
  }
  usernameValidation(params:any) {

    let val = {username: params.value, user_id: this.user.id};
    return new Promise((resolve,reject) => {
      this.service.checkUserByUsername(val).toPromise()
        .then((res:any)=> {
          if(!res.isValid) {
            let myDialog = custom({
              title: "User Exists",
              messageHtml: "<p>"+params.value+" already exist in our system.</p><p>Would you like to load it's data?</p>",
              buttons: [
                {        
                  text: "Yes",  
                  onClick: (e) => {
                    this.service.getUserDataByUsername(val).subscribe(
                      (response:any) => {
                        console.log(response.vendor);
                          this.user = response.user;
                          this.assignedBanks = this.user.banks;
                          this.availableBanks = this.banks;
                          this.availableBanks = this.availableBanks.filter((ar:any) => !this.assignedBanks.find((rm:any) => (rm.bankID === ar.bankID) ));
                         
                      },
                      error=>{
                        console.log(error);
                      }
                    );
                  }          
                },
                {
                  text: "No",             
                }, 
             
              ]
          });
          if(!this.user.id) {
            myDialog.show();
          }
          }
          res.isValid?resolve(res.message): reject(res.message);
        })
    });
}

userTypeValidate() {
  return this.user.userRole;
}
assignSelectedBanks(e:any) {
  if(this.selectedBanks.length<1) {
    return;
  }
  this.assignedBanks.push.apply(this.assignedBanks, this.selectedBanks);
  //this.availableBanks = this.banks.filter((item: any)=>!this.assignedBanks.includes(item));
  this.availableBanks = this.banks.filter((ar:any) => !this.assignedBanks.find((rm:any) => (rm.bankID === ar.bankID) ));
  this.selectedAssignedBanks =[];
  this.selectedBanks = [];
  this.availableBanks.sort();
  this.assignedBanks.sort();
}
assignAllBanks(e:any) {
  this.assignedBanks.push.apply(this.assignedBanks, this.availableBanks);
    //this.availableBanks = this.banks.filter((item: any)=>!this.assignedBanks.includes(item));
    this.availableBanks = this.banks.filter((ar:any) => !this.assignedBanks.find((rm:any) => (rm.bankID === ar.bankID) ));
    this.selectedAssignedBanks =[];
    this.selectedBanks = [];
    this.availableBanks.sort();
    this.assignedBanks.sort();
}

unAssignSelectedBanks(e:any) {
  if(this.selectedAssignedBanks.length<1) {
    return;
  }
  
  //filter out selected banks from assignedbanks list
  //this.assignedBanks = this.assignedBanks.filter((item:any)=>!this.selectedAssignedBanks.includes(item));
  this.assignedBanks = this.assignedBanks.filter((ar:any) => !this.selectedAssignedBanks.find((rm:any) => (rm.bankID === ar.bankID) ));
  //update available banks and remove asignedbanks
  this.availableBanks = this.banks;
  this.availableBanks = this.availableBanks.filter((ar:any) => !this.assignedBanks.find((rm:any) => (rm.bankID === ar.bankID) ));
  this.selectedAssignedBanks =[];
  this.selectedBanks = [];
  this.availableBanks.sort();
  this.assignedBanks.sort();
}
unAssignAllBanks(e:any) {
  this.assignedBanks = [];
  this.availableBanks = this.banks;
  this.selectedAssignedBanks =[];
  this.selectedBanks = [];
  this.availableBanks.sort();
  this.assignedBanks.sort();
}

onFormSubmit(event:any) {
  event.preventDefault();

  this.user.banks = this.assignedBanks.map((obj:any) => obj.bankID);
  this.service.setUser(this.user)
    .subscribe(
      (response: any)=>{
        console.log(response);
        this.user.id = response.id;
        notify({
          message: this.user.username + " has been added.",
          position: {
              my: "center center",
              at: "center center"
          }
      }, "success", 3000);
      }, error=>{
        console.log(error);
      });
      notify({
        message: "Adding Vendor",
        position: {
            my: "center center",
            at: "center center"
        }
    }, "success", 3000);

    
}
}

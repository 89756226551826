import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { CheckServiceService } from '../check-service.service';

@Component({
  selector: 'app-add-corporation',
  templateUrl: './add-corporation.component.html',
  styleUrls: ['./add-corporation.component.css']
})
export class AddCorporationComponent implements OnInit {
  @Input() corporation: any ={};
  @Output() corporationChanged: EventEmitter<any> = new EventEmitter();
  constructor(private service: CheckServiceService) { }

  ngOnInit(): void {
  }
  onFormSubmit(event:any) {
    console.log("Form submitted");
    console.log(this.corporation);
    this.service.setCorporation(this.corporation).subscribe((res)=>{
      this.corporation.id = res.corporationid;
      this.corporationChanged.emit(this.corporation);
    });
    console.log(this.corporation);
   
  }

}

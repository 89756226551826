import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loginStatus = new BehaviorSubject<boolean>(this.isLoggedIn);
  private username = new BehaviorSubject<string>(this.user.display_name);
  private userrole = new BehaviorSubject<string>(this.user.role);

  get isUserLoggedIn() {
    console.log('Login Status Check');
    return this.loginStatus.asObservable();
  }
  get getUsername() {
    return this.username.asObservable();
  }
  get getUserRole() {
    return this.userrole.asObservable();
  }

  constructor(private http: HttpClient, private router: Router) {}

  login(user: string, password: string) {
    return this.http
      .post<any>('https://check.quicktrackinc.com/api/authenticate', {
        username: user,
        password: password,
      })
      .pipe(
        map((res) => {
          localStorage.setItem('access_token', res.jwt);

          return res;
        }),
        catchError((err) => {
          console.log('error caught in service');
          //console.error(err);
          return throwError(err);
        })
      );
  }
  getToken() {
    return localStorage.getItem('access_token');
  }
  get user(): any {
    let user = '';
    let authToken = localStorage.getItem('access_token');
    if (authToken) {
      user = jwtDecode(authToken);
    }
    return user;
  }

  get isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return authToken !== null ? true : false;
  }

  doLogout() {
    localStorage.removeItem('access_token');
    this.router.navigate(['/login']);
  }
}

import {
  Component,
  ElementRef,
  ViewChild,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import notify from 'devextreme/ui/notify';
import { ToWords } from 'to-words';
import { HttpClient } from '@angular/common/http';
import {
  DxButtonGroupComponent,
  DxDataGridComponent,
  DxFileUploaderComponent,
  DxFormComponent,
} from 'devextreme-angular';
import { map } from 'rxjs/operators';
import { CheckServiceService } from './check-service.service';
import { custom } from 'devextreme/ui/dialog';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-check-writer',
  templateUrl: './check-writer.component.html',
  styleUrls: ['./check-writer.component.css'],
})
export class CheckWriterComponent {
  @ViewChild('fileUploader')
  uploader!: DxFileUploaderComponent;
  @ViewChild('form')
  checkForm!: DxFormComponent;
  @ViewChild('pdfViewer') public pdfViewer!: any;
  @ViewChild('iframePDF') public iframePDF!: ElementRef;
  @ViewChild('iframeCheckPDF') public iframeCheckPDF!: ElementRef;
  @ViewChild('invoiceImage') public invoiceImage!: ElementRef;
  @ViewChild('dataGrid') public dataGrid!: DxDataGridComponent;
  @ViewChild('paymentOption') public paymentOption!: DxButtonGroupComponent;
  useNativePDF = true;
  pdfSrc = '';
  maxAmount: any = 3999;
  fileValue: any[] = [];
  title = 'checkprint-project';
  userID = 2;
  check: any = {};
  mops: Array<{ hint: string; text: string }> = [
    {
      hint: 'To issue a check',
      text: 'Check',
    },
    {
      hint: 'Automated Payments',
      text: 'EDI',
    },
    {
      hint: 'To make money order payments',
      text: 'MO',
    },
    {
      hint: 'To make cash payments',
      text: 'Cash',
    },
  ];

  nextCheck: any;
  recentChecks: any;
  vendors: any;
  banks: any;
  vendor: any;
  value: any;
  buttonOptions: any;
  resetButtonOptions: any;
  toWords: ToWords;
  actions: any;
  invoiceBlobImages: any[] = [];
  voidBoxVisible = false;
  invoiceBoxVisible = false;
  loadIndicatorVisible = false;
  checkViewerBoxVisible = false;
  voidReasons = [
    { id: 1, desc: 'Print Error' },
    { id: 2, desc: 'Amount Incorrect' },
    { id: 3, desc: 'Payee Incorrect' },
    { id: 4, desc: 'Check Returned' },
    { id: 4, desc: 'Vendor Lost the Check' },
    { id: 4, desc: 'Incorrect Bank A/C' },
  ];
  selectedCheck: any;
  voidDecisionBox: any;
  constructor(
    private service: CheckServiceService,
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.showVoidBox = this.showVoidBox.bind(this);
    this.onVoidSubmit = this.onVoidSubmit.bind(this);
    this.onVoidFormSubmit = this.onVoidFormSubmit.bind(this);
    this.onResetForm = this.onResetForm.bind(this);
    this.toWords = new ToWords({
      localeCode: 'en-US',
      converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
      },
    });
    this.actions = [
      { id: 1, text: 'Print', icon: 'print' },
      { id: 2, text: 'Confirm', icon: 'fieldchooser' },
      { id: 3, text: 'Void', icon: 'trash' },
      { id: 4, text: 'Invoice', icon: 'doc' },
    ];
    //this.vendors = service.getVendors();

    this.buttonOptions = {
      text: 'Submit',
      type: 'success',
      useSubmitBehavior: true,
    };
    this.resetButtonOptions = {
      text: 'Reset',
      type: 'normal',
      onClick: this.onResetForm,
      userSubmitBehavior: false,
    };
  }

  ngOnInit(): void {
    this.check.mop = 'Check';
    this.maxAmount = this.authService.user.maxAmount;
    //console.log("AMOUNT IS: " + this.maxAmount);
    this.service.getBanksByUser().subscribe(
      (response: any) => {
        this.banks = response.banks;
        console.log(response);
      },
      (error) => {
        console.error('error caught in component');
        //this.errorMsg = error;
        throw error;
      }
    );

    this.service.getRecentChecks().subscribe(
      (response: any) => {
        this.recentChecks = response.checks;
        console.log(response);
      },
      (error) => {
        console.error('error caught in component');
        //this.errorMsg = error;
        throw error;
      }
    );
  }
  methodOfPaymentClick(e: any) {
    if (!this.check.bank) {
      return;
    }
    this.check.mop = e.itemData.text;
    if (e.itemData.text == 'MO') {
      this.nextCheck = 1;
      this.check.checkno = this.nextCheck;
    } else if (e.itemData.text == 'Cash') {
      this.nextCheck = 0;
      this.check.checkno = this.nextCheck;
    } else if (e.itemData.text == 'EDI') {
      this.nextCheck = 2;
      this.check.checkno = this.nextCheck;
    } else if (e.itemData.text == 'Check') {
      this.service
        .getVendorsByBank({ bankID: this.check.bank.bankID })
        .subscribe(
          (response: any) => {
            this.vendors = response.vendors;
            this.nextCheck = response.nextCheck;
            console.log(response);
          },
          (error) => {
            console.error('error caught in component');
            //this.errorMsg = error;
            throw error;
          }
        );
    }
    notify(
      { message: 'Paying as "' + e.itemData.text, width: 320 },
      'success',
      1000
    );
  }
  vendorSelected(event: any) {
    console.log(event);
    try {
      this.dataGrid.instance.filter([
        ['dba', '=', this.check.bank.dba],
        'and',
        ['payee', '=', event.value.vendor_name],
      ]);
    } catch (error) {
      // catch the error or take no action here for no fiteration
    }
  }
  bankSelected(event: any) {
    console.log(this.check);
    if (!this.check.bank) {
      return;
    }
    this.dataGrid.instance.filter(['dba', '=', event.value.dba]);
    this.service.getVendorsByBank({ bankID: this.check.bank.bankID }).subscribe(
      (response: any) => {
        this.check.mop = 'Check';
        this.vendors = response.vendors;
        this.nextCheck = response.nextCheck;
        console.log(response);
      },
      (error) => {
        console.error('error caught in component');
        //this.errorMsg = error;
        throw error;
      }
    );
  }

  beforeUpload(event: any) {
    // pass jwt auth token before upload.
    const authToken = this.authService.getToken();
    event.request.setRequestHeader('Authorization', 'jwt ' + authToken);
  }
  fileUploaded(event: any) {
    // get response returend after file upload to server.
    let response = event.request.response;

    console.log(response);

    //append this object to form element.
    this.invoiceBlobImages.push(response);
    console.log(this.invoiceBlobImages);
  }

  isVoidable(row: any) {
    return row.data.void == 0 ? true : false;
  }
  isConfirmed(row: any) {
    return row.data.confirm == 0 ? true : false;
  }
  isVoided(e: any) {
    return e.row.data.void == 1 ? true : false;
  }
  rowVoided(row: any) {
    return row.data.voidreasonid ? true : false;
  }
  rowConfirmed(row: any) {
    console.log(row.data);
    return row.data.isConfirm ? true : false;
  }
  voidReason(e: any) {
    return e.row.data.void_reason;
  }
  submit(checkForm: any) {
    console.log(checkForm);
    console.log(checkForm.value);
  }
  onFormSubmit(e: any) {
    this.check.invoiceData = this.invoiceBlobImages;

    if (!this.check.invoiceData || this.check.invoiceData.length == 0) {
      let myDialog = custom({
        title: 'Error',
        messageHtml:
          '<h5>Please upload invoice</h5><p>You need to submit a proof or invoice before the system allows you to print the check.</p>',
        buttons: [
          {
            text: 'OK',
          },
          // ...
        ],
      });

      myDialog.show();
      return;
    }
    this.check.payee = this.check.vendor.vendor_name;
    this.check.created_date = new Date().toLocaleDateString();
    // set filename of invoices uploaded currently as check invoiceData

    this.check.dba = this.check.bank.dba;
    if (this.check.mop != 'Check') {
      console.log('Creating NON CHECK');
      this.service.createNonCheck(this.check).subscribe(
        (response: any) => {
          console.log(response);
          this.check.id = response.checkID;
          this.check.checkno = this.nextCheck;
          this.recentChecks.splice(0, 0, this.check);
          this.check = {};
          this.nextCheck = '';
          this.paymentOption.selectedItemKeys = ['Check'];
          console.log(response);
        },
        (error: any) => {
          console.log(error);
        }
      );
    } else {
      console.log('Creating Check');
      this.service.createCheck(this.check).subscribe(
        (response: any) => {
          console.log(response);
          this.check.id = response.checkID;
          this.check.checkno = this.nextCheck;
          this.recentChecks.splice(0, 0, this.check);
          this.check = {};
          this.nextCheck = '';
          console.log(response);
          this.paymentOption.selectedItemKeys = ['Check'];
        },
        (error) => {
          console.log(error);
        }
      );
    }

    this.uploader.instance.reset();
    this.invoiceBlobImages = [];

    notify(
      {
        message: 'Check Submitted',
        position: {
          my: 'center center',
          at: 'center center',
        },
      },
      'success',
      3000
    );

    e.preventDefault();
  }
  onResetForm(event: any) {
    console.log('Reset');
    console.log(event);
    //this.checkForm.instance.resetValues();
    this.check = {};
    this.nextCheck = '';
    this.invoiceBlobImages = [];
    this.paymentOption.selectedItemKeys = ['Check'];
    this.uploader.instance.reset();
  }
  onVoidFormSubmit(rowData: any) {
    //console.log(rowData);
    console.log('submit to void the check');
    this.voidBoxVisible = false;
    this.selectedCheck.voidreasonid = this.voidDecisionBox.voidreasonid;
    this.selectedCheck.voidinfo = this.voidDecisionBox.voidinfo;

    //send selectbox to server
    console.log(this.selectedCheck);

    let voidableCheck = {
      check_id: this.selectedCheck.id,
      voidreasonid: this.selectedCheck.voidreasonid,
      voidinfo: this.selectedCheck.voidinfo,
    };

    this.service.voidCheck(voidableCheck).subscribe((res: any) => {
      this.dataGrid.instance.refresh();
      notify(
        {
          message: 'Check was Voided',
          position: {
            my: 'center',
            at: 'center',
            of: window,
            offset: '0 0',
          },
        },
        'error'
      );
    });

    this.voidBoxVisible = false;
    this.selectedCheck.canVoid = 0;
    console.log('update backend here');
    notify(
      {
        message: 'The check was voided.',
        position: {
          my: 'center center',
          at: 'center center',
        },
      },
      'success',
      3000
    );

    rowData.preventDefault();
  }
  // this method is not used
  onVoidSubmit(row: any) {
    this.voidBoxVisible = false;

    console.log('Voiding this check');
    console.log(row.data);
    let voidableCheck = {
      check_id: this.selectedCheck.id,
      voidreasonid: this.selectedCheck.voidreasonid,
      voidinfo: this.selectedCheck.voidinfo,
    };

    this.service.voidCheck(voidableCheck).subscribe((res: any) => {
      this.dataGrid.instance.refresh();
      notify(
        {
          message: 'Check was Voided',
          position: {
            my: 'center',
            at: 'center',
            of: window,
            offset: '0 0',
          },
        },
        'error'
      );
    });
  }
  amountChanged(e?: any) {
    console.log('Amount changed');
    if (this.check.amount)
      this.check.in_words = this.toWords.convert(this.check.amount);
  }

  showVoidBox(row: any, event: any) {
    this.selectedCheck = row.data;

    //console.log(row);
    //console.log(event.itemData);
    if (event.itemData.text == 'Void') {
      this.voidDecisionBox = {};
      this.voidBoxVisible = true;
      this.selectedCheck.canVoid = 1;
      if (this.selectedCheck.voidreasonid != null) {
        this.selectedCheck.canVoid = 0;
      }
    }
    if (event.itemData.text == 'Print') {
      console.log('Print');
      if (
        this.selectedCheck.voidreasonid > 0 ||
        this.selectedCheck.checkno <= 2
      ) {
        notify(
          {
            message: 'Invalid check!',
            position: {
              my: 'center',
              at: 'center',
              of: window,
              offset: '0 0',
            },
          },
          'error'
        );
        return;
      }

      this.pdfSrc =
        'https://check.quicktrackinc.com/api/printcheck?checkid=' + row.data.id;
      if (this.iframeCheckPDF) {
        this.iframeCheckPDF.nativeElement.style =
          'visibility: hidden; height:0;';
      }
      this.loadIndicatorVisible = true;
      this.downloadFile(this.pdfSrc).subscribe((res: any) => {
        let pdfobj = new Blob([res], { type: 'application/pdf' });
        this.iframeCheckPDF.nativeElement.style =
          'visibility: visible;height: 100%;';
        this.iframeCheckPDF.nativeElement.data = URL.createObjectURL(pdfobj);
        this.loadIndicatorVisible = false;
      });
      this.checkViewerBoxVisible = true;
      notify(
        {
          message: 'Printing the check...',
          position: {
            my: 'center center',
            at: 'center center',
          },
        },
        'success',
        3000
      );
    }
    if (event.itemData.text == 'Confirm') {
      if (this.selectedCheck.voidreasonid > 0) {
        notify(
          {
            message: 'Unable to confirm voided check',
            position: {
              my: 'center',
              at: 'center',
              of: window,
              offset: '0 0',
            },
          },
          'error'
        );
        return;
      }
      if (this.selectedCheck.isConfirm) {
        notify(
          {
            message: 'This check has already been confirmed!',
            position: {
              my: 'center',
              at: 'center',
              of: window,
              offset: '0 0',
            },
          },
          'error'
        );
        return;
      }
      console.log('Confirming');

      let confirmableCheck = { check_id: this.selectedCheck.id };
      this.service.confirmCheck(confirmableCheck).subscribe((res: any) => {
        this.selectedCheck.isConfirm = true;
        this.dataGrid.instance.refresh();

        notify(
          {
            message: 'Check confirmed!',
            position: {
              my: 'center center',
              at: 'center center',
            },
          },
          'success',
          3000
        );
      });
    }
    if (event.itemData.text == 'Invoice') {
      this.loadIndicatorVisible = true;
      console.log('Invoice');
      this.pdfSrc =
        'https://check.quicktrackinc.com/api/download?blob=' +
        encodeURIComponent(row.data.invoiceData[0]);
      let fileType = row.data.invoiceData[0].split('.').pop();
      let fileMIME = '';
      if (fileType === 'pdf') {
        fileMIME = 'application/' + fileType;
      } else {
        fileMIME = 'image/' + fileType;
      }

      if (this.invoiceImage) {
        this.invoiceImage.nativeElement.style = 'visibility: hidden;';
      }
      if (this.iframePDF) {
        this.iframePDF.nativeElement.style = 'visibility: hidden; height:0;';
      }
      this.downloadFile(this.pdfSrc).subscribe((res: any) => {
        if (this.useNativePDF) {
          let pdfobj = new Blob([res], { type: fileMIME });
          //this.iframePDF.nativeElement.src = URL.createObjectURL(pdfobj);
          if (fileType === 'pdf' || fileType === 'PDF') {
            console.log('PDF file Received');
            this.iframePDF.nativeElement.type = fileMIME;
            this.iframePDF.nativeElement.style =
              'visibility: visible; height:100%;';
            this.iframePDF.nativeElement.data = URL.createObjectURL(pdfobj);
          } else {
            console.log('non pdf file Received');
            this.invoiceImage.nativeElement.style = 'visibility:visible;';
            this.invoiceImage.nativeElement.src = URL.createObjectURL(pdfobj);
          }
          this.loadIndicatorVisible = false;
        } else {
          this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
          this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
        }
      });

      this.invoiceBoxVisible = true;
    }
  }

  showInvoiceBox(row: any, event: any) {
    this.selectedCheck = row.data;
  }
  invoiceSelected(e: any) {
    this.loadIndicatorVisible = true;
    this.pdfSrc =
      'https://check.quicktrackinc.com/api/download?blob=' +
      encodeURIComponent(e.itemData);
    let fileType = e.itemData.split('.').pop();
    let fileMIME = '';
    if (fileType === 'pdf') {
      fileMIME = 'application/' + fileType;
    } else {
      fileMIME = 'image/' + fileType;
    }

    if (this.invoiceImage) {
      this.invoiceImage.nativeElement.style = 'visibility: hidden;';
    }
    if (this.iframePDF) {
      this.iframePDF.nativeElement.style = 'visibility: hidden; height:0;';
    }

    this.downloadFile(this.pdfSrc).subscribe((res: any) => {
      if (this.useNativePDF) {
        let pdfobj = new Blob([res], { type: fileMIME });
        //this.iframePDF.nativeElement.src = URL.createObjectURL(pdfobj);
        if (fileType === 'pdf' || fileType === 'PDF') {
          console.log('PDF file Received');
          this.iframePDF.nativeElement.type = fileMIME;
          this.iframePDF.nativeElement.style =
            'visibility: visible; height:100%;';
          this.iframePDF.nativeElement.data = URL.createObjectURL(pdfobj);
        } else {
          console.log('non pdf file Received');
          this.invoiceImage.nativeElement.style = 'visibility:visible;';
          this.invoiceImage.nativeElement.src = URL.createObjectURL(pdfobj);
        }
        this.loadIndicatorVisible = false;
      } else {
        this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
        this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
      }
    });
  }
  beforePrint() {
    console.log('Print Process');

    let viewerOptions = this.pdfViewer.PDFViewerApplicationOptions;
    console.log(viewerOptions);
  }
  dataGridPrepared(e: any) {
    if (e.rowType === 'data') {
      console.log(e);
      if (e.data.voidreasonid) {
        e.rowElement.style.textDecoration = 'line-through';
        e.rowElement.style.fontWeight = 'normal';
        e.rowElement.style.color = '#777';
      }
      if (!e.data.voidreasonid && !e.data.isConfirm) {
        e.rowElement.style.fontWeight = 'bold';
      }
    }
  }
  popup_hidden(e: any) {
    // Handler of the "hidden" event
    if (this.iframePDF) {
      this.iframePDF.nativeElement.data = '';
    }
    if (this.iframeCheckPDF) {
      this.iframeCheckPDF.nativeElement.data = '';
    }
  }
  private downloadFile(url: string): any {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((result: any) => {
        return result;
      })
    );
  }
}

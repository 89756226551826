<div class="container-xxl m-3 p-1">
	<form #form method="post" id="form" (submit)="onFormSubmit($event)">
		<div class="row">
			<div class="col-12">
				<div class="container dx-card">
					<dx-form [(formData)]="bank" labelLocation="left" [showValidationSummary]="true" validationGroup="vendorData">
						<dxi-item itemType="group" [colCount]="2">
							<dxi-item itemType="group" caption="Bank Account Information">
                                <dxi-item dataField="bankName" editorType="dxAutocomplete" [editorOptions]="{ dataSource: allbanknames,searchExpr:'bankName',valueExpr:'bankName'}">
									<dxi-validation-rule type="required" message="Bank name is required"> </dxi-validation-rule>
                                </dxi-item>
								<dxi-item dataField="dba" editorType="dxAutocomplete" [editorOptions]="{ dataSource: allstores,searchExpr:'name'}"></dxi-item>
                                <dxi-item dataField="accountName" editorType="dxAutocomplete" [editorOptions]="{ dataSource: allstores,valueExpr:'id',displayExpr:'name', searchExpr:'name'}">
									<dxi-validation-rule type="required" message="Account name is required"> </dxi-validation-rule>
                                </dxi-item>
                                <dxi-item dataField="routing"><dxi-validation-rule type="required" message="Routing number is required"> </dxi-validation-rule></dxi-item>
								<dxi-item dataField="account"><dxi-validation-rule type="required" message="Account number is required"> </dxi-validation-rule></dxi-item>
								<dxi-item dataField="signatureID"><dxi-validation-rule type="required" message="Signature ID is required"> </dxi-validation-rule></dxi-item>
								
							</dxi-item>
							<dxi-item itemType="group" caption="Corporation">
								<dxi-item dataField="corporation" [template]="'corporationTemplate'"></dxi-item>
								<dxi-item itemType="group" caption="Return Address">
									<dxi-item dataField="address1">
										<dxi-validation-rule type="required" message="Address is required"> </dxi-validation-rule>
									</dxi-item>
									<dxi-item dataField="address2"></dxi-item>
									<dxi-item dataField="city">
										<dxi-validation-rule type="required" message="City is required"> </dxi-validation-rule>
									</dxi-item>
									<dxi-item itemType="group" [colCount]="2">
										<dxi-item dataField="state">
											<dxi-validation-rule type="required" message="State is required"> </dxi-validation-rule>
										</dxi-item>
										<dxi-item dataField="zip">
											<dxi-validation-rule type="required" message="Zip is required"> </dxi-validation-rule>
										</dxi-item>
									</dxi-item>
								</dxi-item>
							</dxi-item>
						</dxi-item>
						<div *dxTemplate="let data of 'corporationTemplate'">
							<dx-select-box [dataSource]="allcorporations" valueExpr="id" displayExpr="corporation_name" [acceptCustomValue]="true" [searchEnabled]="true" [searchExpr]='["corporation_name"]' [(value)]="bank.corporation" valueExpr="this">
								<dxi-button name="btn" location="after" [options]="addNewCorporationButtonOption"></dxi-button>
								<dxi-button name="dropDown"></dxi-button>
								<div *dxTemplate="let data of 'item'" class="custom-item">
									<div class="row">
										<div class="col">{{data.corporation_name}}</div>
									</div>
									<div class="row">
										<div class="col text-right"><span>{{data.ein}}</span></div>
									</div>
								</div>
							</dx-select-box>
						</div>
						<dxi-item [template]="'actionTemplate'">
							<dxo-label [visible]="false"></dxo-label>
						</dxi-item>
						<div *dxTemplate="let content of 'actionTemplate'">
							<div class="group-container">
								<dx-button class="right-align-buttons" type="normal" text="Cancel"> </dx-button>
								<dx-button class="right-align-buttons" type="success" text="Add Bank" [useSubmitBehavior]="true" validationGroup="vendorData"> </dx-button>
							</div>
						</div>
					</dx-form>
				</div>
			</div>
			<div class="col-6">
				<div class="container dx-card"> </div>
			</div>
		</div>
	</form>
</div>
<dx-popup title="New Corporation" [(visible)]="newCorporationVisible">
	<div *dxTemplate="let data of 'content'">
		<app-add-corporation [corporation]="bank.corporation" (corporationChanged)="corporationChangedHandler($event)"></app-add-corporation>
	</div>
</dx-popup>